import { ChangeDetectionStrategy, Component, computed, HostBinding, input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[bds-button]',
  standalone: true,
  imports: [],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  public icon = input<string | boolean>();
  public _icon = computed<boolean>(() => {
    switch (this.icon()) {
      case 'false':
        return false;
      case '':
        return true;
      default:
        return !!this.icon();
    }
  });

  // Bind the classes to the host element, accessing the signals directly
  @HostBinding('class')
  private get hostClassBinding(): string {
    return `bds-button-type--${this._icon() ? 'icon' : 'regular'}`;
  }
}
